import * as React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { CustomSnackbarContentWrapper } from "./CustomSnackbar";


const ErrorSnackbar: React.SFC<{ errorMessage?: string, onClose: () => void }> = props => (
    <Snackbar
        open={props.errorMessage != null}
        onClose={props.onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
        <CustomSnackbarContentWrapper
            onClose={props.onClose}
            variant="error"
            message={props.errorMessage}
        />
    </Snackbar>
);

export default ErrorSnackbar;