
import { AppConfig } from "../AppConfig";
import { WebClient, ServerResponse, Server } from "../utilities/WebClient";
import { Contact } from "./ContactService";

export interface FileDetails {
    id: number;
    fileNumber: string;
    name: string;
    type: string;
    opened: Date;
    closed?: Date;
    isClosed: boolean;
    suitIsFiled: boolean;
    suitDate?: Date;
    court?: string;
    docketNumber?: string;
    courtNotes?: string;
    notes?: string;
    retainUntil?: Date;
    disposalStatus?: string;
    disposedDate?: Date;
    shredded: boolean;
    fileDocsRetained: boolean;
    judgementDocsRetained: boolean;
    receiptReleaseDocsRetained: boolean;
    otherDocsRetained: boolean;
    otherRetainedDocDescription?: string;
}

export interface FileDetailsServerResponse {
    id: number;
    fileNumber: string;
    name?: string;
    type: string;
    opened: string;
    closed?: string;
    isClosed: boolean;
    suitIsFiled: boolean;
    suitDate?: string;
    court?: string;
    docketNumber?: string;
    courtNotes?: string;
    notes?: string;
    retainUntil?: string;
    disposalStatus?: string;
    disposedDate?: string;
    shredded: boolean;
    fileDocsRetained: boolean;
    judgementDocsRetained: boolean;
    receiptReleaseDocsRetained: boolean;
    otherDocsRetained: boolean;
    otherRetainedDocDescription?: string;
}

export interface ContactFileRelationship {
    id: number;
    contactId: number;
    contactName: string;
    detailsId: number;
    fileNumber: string;
    relation: string;
    type: string;
    isClosed: boolean;
}

export class FileService {
    static async GetFileDetail(fileId: number) {
        let endpoint = `${AppConfig.ApiServer}/file/${fileId}`;
        let response = await WebClient.Get<FileDetailsServerResponse>(endpoint);

        if (Server.response(response)) {
            let newResponse: ServerResponse<FileDetails> = {
                message: response.message,
                data: FileService.ServerFileDetailsToFileDetails(response.data)
            };
            return newResponse;
        }
        return response;
    }

    static async SearchFileDetail(term: string, byFileNumber: boolean) {
        let endpoint = `${AppConfig.ApiServer}/file/search?term=${term}&byFileNumber=${byFileNumber}`;
        let response = await WebClient.Get<FileDetailsServerResponse[]>(endpoint);

        if (Server.response(response)) {
            let newResponse: ServerResponse<FileDetails[]> = {
                message: response.message,
                data: response.data.map(f => FileService.ServerFileDetailsToFileDetails(f))
            };
            return newResponse;
        }
        return response;
    }

    static async SaveFileDetails(fileDetail: FileDetails) {
        let endpoint = `${AppConfig.ApiServer}/file/update`;
        let response = await WebClient.Post<FileDetailsServerResponse>(endpoint, fileDetail);
        if (Server.response(response)) {
            let newResponse: ServerResponse<FileDetails> = {
                message: response.message,
                data: FileService.ServerFileDetailsToFileDetails(response.data)
            };
            return newResponse;
        }
        return response;
    }

    static async GetRelationshipsByContact(contactId: number) {
        let endpoint = `${AppConfig.ApiServer}/file/relationships/?contactId=${contactId}`;
        return WebClient.Get<ContactFileRelationship[]>(endpoint);
    }

    static async AddContact(relationship: ContactFileRelationship) {
        let endpoint = `${AppConfig.ApiServer}/file/relationships`;
        return WebClient.Post<ContactFileRelationship>(endpoint, relationship);
    }

    static async CreateContact(contact: Contact, file: FileDetails, relationshipType: string) {
        let endpoint = `${AppConfig.ApiServer}/file/contact`;
        let request = { addContactRequest: contact, file, relationshipType };
        return WebClient.Post<ContactFileRelationship>(endpoint, request);
    }

    static async RemoveContact(relationship: ContactFileRelationship)
    {
        let endpoint = `${AppConfig.ApiServer}/file/remove-contact`;
        let request = { relationshipId: relationship.id };
        return WebClient.Post<null>(endpoint, request);
    }

    static async GetRelationshipsByFile(fileId: number) {
        let endpoint = `${AppConfig.ApiServer}/file/relationships/?fileId=${fileId}`;
        return WebClient.Get<ContactFileRelationship[]>(endpoint);
    }

    static isFile(obj: Contact | FileDetails | ContactFileRelationship): obj is FileDetails {
        let model = obj as any;
        return model.fileNumber !== undefined && model.opened !== undefined;
    }

    static ServerFileDetailsToFileDetails(file: FileDetailsServerResponse) {
        let fileDetails: FileDetails = {
            ...file,
            name: file.name ? file.name : "",
            opened: new Date(file.opened),
            closed: file.closed ? new Date(file.closed) : undefined,
            suitDate: file.suitDate ? new Date(file.suitDate) : undefined,
            retainUntil: file.retainUntil ? new Date(file.retainUntil) : undefined,
            disposedDate: file.disposedDate ? new Date(file.disposedDate) : undefined
        };
        return fileDetails;
    }
}

