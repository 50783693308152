import * as React from 'react';
import withStyles, { WithStyles, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { RouteComponentProps } from 'react-router';
import { Contact } from '../services/ContactService';
import ContactSearch from '../components/ContactSearch';


const styles: StyleRulesCallback<any> = theme => ({
    root: {}
});

interface HomeProps extends RouteComponentProps<void>, WithStyles<any> {}
interface HomeState {
    contacts: Contact[]
    searchTerm: string
    submittedTerm: string
    searching: boolean
}

class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props)
        this.state = {
            contacts: [],
            searchTerm: "",
            submittedTerm: "",
            searching: false
        }
    }

    onContactSelect = (contact: Contact) => {
        this.props.history.push(`contact/${contact.id}`)
    }

    render() {
        return(
            <div style={{paddingTop: 15}}>
                <ContactSearch onContactSelect={contact => this.onContactSelect(contact)}/>
            </div>
        )
    }
}

export default withRoot(withStyles(styles)(Home));