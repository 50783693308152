export const fullHostname = location.protocol+'//'+ location.hostname+(location.port ? ':'+location.port: '');
const hostname = window.location.host;

class Production {
    static ApiServer = process.env.REACT_APP_API_SERVER ? process.env.REACT_APP_API_SERVER : ""
}

class LocalDev {
    static ApiServer = ""
}

export enum Environment {LocalDev, Production }

export class AppConfig {
    static DetermineEnvironment = () => {
        if(hostname.toLowerCase().indexOf("localhost") !== -1) {
            return Environment.LocalDev;
        }
        if(hostname.toLowerCase().indexOf("koehler-firm") !== -1){
            return Environment.Production;
        }
        return Environment.LocalDev;
    }

    static HostingEnvironment : Environment = AppConfig.DetermineEnvironment();

    static IsLocalDev : boolean = AppConfig.HostingEnvironment === Environment.LocalDev;
    static ApiServer = AppConfig.IsLocalDev ? LocalDev.ApiServer : Production.ApiServer;
}

