import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import withRoot from "../../withRoot";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { DateUtil } from "../../utilities/DateUtil";
import { FileDetails } from "../../services/FileService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { isNullEmptyOrWhitespace } from "../../utilities/HelperFunctions";


const styles = (theme: Theme) => (
    createStyles({
        form: {
            padding: 20
        },
        buttonContainer: {
            paddingTop: 10
        }
    }));

export interface OnCourtInfoResponse {
    suitIsFiled: boolean;
    suitDate?: Date;
    court?: string;
    courtNotes?: string;
    docketNumber?: string;
}


interface Props extends WithStyles<typeof styles> {
    open: boolean
    fileDetails: FileDetails
    courts: string[]
    onClose: () => void
    onSave: (response: OnCourtInfoResponse) => void
}
interface State {
    suitIsFiled: boolean;
    suitDate: string;
    court: string;
    docketNumber: string;
    notes: string;
}

class CourtInfoDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let file = props.fileDetails;

        this.state = {
            suitIsFiled: file.suitIsFiled,
            suitDate: file.suitDate ? DateUtil.toInputFormat(file.suitDate) : DateUtil.toInputFormat(new Date()),
            court: file.court ? file.court : "",
            docketNumber: file.docketNumber ? file.docketNumber : "",
            notes: file.courtNotes ? file.courtNotes : ""
        }
    }

    onSave = () => {
        const {
            suitIsFiled, suitDate, court, notes, docketNumber
        } = this.state

        let response: OnCourtInfoResponse = {
            suitIsFiled,
            suitDate: suitIsFiled ? DateUtil.inputFormatToDate(suitDate) : undefined,
            court: court === "" ? undefined : court,
            docketNumber: isNullEmptyOrWhitespace(docketNumber) ? undefined : docketNumber,
            courtNotes: isNullEmptyOrWhitespace(notes) ? undefined : notes
        }

        this.props.onSave(response);
    }

    render() {
        const { classes } = this.props

        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <form className={classes.form} onSubmit={e => { e.preventDefault(); this.onSave(); }}>
                    <Typography variant="h5" align="center">Court Info</Typography>
                    <Typography variant="body2" align="center">{this.props.fileDetails.fileNumber} ({this.props.fileDetails.type})</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.suitIsFiled}
                                onChange={e => this.setState({ suitIsFiled: e.target.checked })}
                            />
                        }
                        label="Suit Is Filed"
                    />
                    <br />
                    <TextField
                        label="Suit Original File Date"
                        required={this.state.suitIsFiled}
                        disabled={!this.state.suitIsFiled}
                        value={this.state.suitDate}
                        onChange={e => this.setState({ suitDate: e.target.value })}
                        type="date"
                        fullWidth
                    />
                    <TextField
                        label="Docket Number"
                        value={this.state.docketNumber}
                        onChange={e => this.setState({ docketNumber: e.target.value })}
                        fullWidth
                    />
                    <TextField
                        label="Notes"
                        value={this.state.notes}
                        onChange={e => this.setState({ notes: e.target.value })}
                        fullWidth
                        multiline
                    />
                    <FormControl fullWidth>
                        <InputLabel htmlFor="court-name">Court</InputLabel>
                        <Select
                            value={this.state.court}
                            onChange={e => this.setState({ court: e.target.value })}
                            inputProps={{
                                id: 'court-name',
                            }}
                        >
                            <MenuItem value="">
                                <em>Select Court</em>
                            </MenuItem>
                            {
                                this.state.court !== ""
                                &&
                                !this.props.courts.some(c => c === this.state.court)
                                &&
                                <MenuItem value={this.state.court}>{this.state.court}</MenuItem>
                            }
                            {
                                this.props.courts.map(court => (
                                    <MenuItem key={court} value={court}>{court}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <Grid container justify="flex-end" className={classes.buttonContainer} >
                        <Grid item>
                            <Button variant="text" onClick={this.props.onClose}>Cancel</Button>
                            <Button variant="contained" color="primary" type="submit">Save</Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        );
    }
}

export default withRoot(withStyles(styles)(CourtInfoDialog));