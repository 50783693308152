import * as React from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { red, green, amber, blue } from '@material-ui/core/colors';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const customSnackbarstyles = (theme: Theme) => (
    createStyles({
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
        info: {
            backgroundColor: blue[600],
        },
        warning: {
            backgroundColor: amber[700],
        },
        icon: {
            fontSize: 20,
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing.unit,
        },
        message: {
            display: 'flex',
            alignItems: 'center',
        },
    }));


interface CustomSnackbarContentProps extends WithStyles<typeof customSnackbarstyles> {
    className?: string;
    message: any;
    onClose?: () => void;
    variant: 'success' | 'warning' | 'error' | 'info'
}

function CustomSnackbarContent(props: CustomSnackbarContentProps) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

export const CustomSnackbarContentWrapper = withStyles(customSnackbarstyles)(CustomSnackbarContent);
