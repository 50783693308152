import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../../withRoot';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { ContactService, Contact } from '../../services/ContactService';
import { Server } from '../../utilities/WebClient';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Phone, PhoneService } from '../../services/PhoneService';
import Typography from '@material-ui/core/Typography';
import { ContactFileRelationship, FileService, FileDetails } from '../../services/FileService';
import { Theme, createStyles } from '@material-ui/core/styles';
import { isNullEmptyOrWhitespace } from '../../utilities/HelperFunctions';
import PageLoader from '../../components/PageLoader';
import SuccessSnackbar from '../../components/SuccessSnackbar';
import ErrorSnackbar from '../../components/ErrorSnackbar';
import SearchFileDialog from './SearchFileDialog';


const styles = (theme: Theme) => (
    createStyles({
        root: {},
        paper: {
            width: "100%",
            padding: 20,
        },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
            width: 200,
        },
        saveButton: {
            padding: 10,
            paddingLeft: 30,
            paddingRight: 30
        }
    }));

interface Params {
    id: string;
}

interface Props extends RouteComponentProps<Params>, WithStyles<typeof styles> { }
interface State {
    id: number
    name: string
    street: string
    city: string
    state: string
    zip?: number
    email: string
    deceased: boolean
    phoneNumbers: Phone[]
    fileRelationships: ContactFileRelationship[]
    addExistingFileDialog: boolean
    relationshipsToAdd: ContactFileRelationship[]
    relationshipsToRemove: ContactFileRelationship[]
    error?: string
    success?: string
}

class ContactDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            id: 0,
            name: "",
            street: "",
            city: "",
            state: "",
            email: "",
            deceased: false,
            phoneNumbers: [],
            fileRelationships: [],
            addExistingFileDialog: false,
            relationshipsToAdd: [],
            relationshipsToRemove: []
        }
    }

    componentDidMount() {
        const contactIdParam = this.props.match.params.id;
        if (!contactIdParam)
            this.props.history.replace("/")
        else {
            const contactId = parseInt(contactIdParam);
            ContactService.GetById(contactId)
                .then(result => {
                    if (Server.response(result)) {
                        const contact = result.data.contact;
                        this.setState({
                            id: contact.id,
                            name: contact.name,
                            email: contact.email ? contact.email : "",
                            street: contact.street ? contact.street : "",
                            city: contact.city ? contact.city : "",
                            state: contact.state ? contact.state : "",
                            zip: contact.zip === 0 ? undefined : contact.zip,
                            deceased: contact.deceased
                        })
                    }
                    else
                        alert(result.message)
                })

            PhoneService.GetPhonesByContact(contactId)
                .then(result => {
                    if (Server.response(result))
                        this.setState({ phoneNumbers: result.data.phones })
                    else
                        alert(result.message)
                });

            FileService.GetRelationshipsByContact(contactId)
                .then(result => {
                    if (Server.response(result))
                        this.setState({ fileRelationships: result.data })
                    else
                        alert(result.message)
                });
        }
    }

    onFileSelected = (fileId: number) => {
        this.props.history.push(`/file/${fileId}`)
    }

    onRelationshipRemoved = (relationship: ContactFileRelationship) => {
        const { fileRelationships, relationshipsToAdd, relationshipsToRemove } = this.state;

        this.setState({
            fileRelationships: fileRelationships.filter(r => r.detailsId != relationship.detailsId),
            relationshipsToAdd: relationshipsToAdd.filter(r => r.detailsId != relationship.detailsId),
            relationshipsToRemove: [...relationshipsToRemove, relationship]
        });
    }

    onNewRelationToExistingFile = (file: FileDetails, relation: string) => {
        const { id: contactId, name: contactName, relationshipsToAdd, fileRelationships } = this.state;

        if (fileRelationships.find(r => r.detailsId === file.id)) {
            this.popMessage(`${file.fileNumber} is already on the contact`, false);
            return;
        }


        let relationship: ContactFileRelationship = {
            contactId,
            contactName,
            fileNumber: file.fileNumber,
            detailsId: file.id,
            type: file.type,
            isClosed: file.isClosed,
            relation,
            id: 0
        }

        this.setState({
            relationshipsToAdd: [...relationshipsToAdd, relationship],
            fileRelationships: [...fileRelationships, relationship]
        });
    }

    onSaveContact = async () => {
        let { id, name, street, city, state, zip, email, deceased, relationshipsToAdd, relationshipsToRemove } = this.state;
        const contact: Contact = {
            id: id,
            name: name,
            street: isNullEmptyOrWhitespace(street) ? undefined : street,
            city: isNullEmptyOrWhitespace(city) ? undefined : city,
            state: isNullEmptyOrWhitespace(state) ? undefined : state,
            zip: zip ? zip : 0,
            email: isNullEmptyOrWhitespace(email) ? undefined : email,
            deceased: deceased
        };

        let saveContactResponse = await ContactService.SaveContact(contact)
        if (!Server.response(saveContactResponse)) {
            this.popMessage(saveContactResponse.message, false);
            return;
        }
        if (relationshipsToAdd.length > 0) {
            for (let i = 0; i < relationshipsToAdd.length; i++) {
                const relation = relationshipsToAdd[i];
                let relationAddResponse = await ContactService.AddExistingFile(relation);
                if (Server.response(relationAddResponse)) {
                    relationshipsToAdd = relationshipsToAdd.splice(i, 1)
                    this.setState({ relationshipsToAdd })
                } else {
                    this.popMessage(relationAddResponse.message, false);
                    return;
                }
            }
        }
        if (relationshipsToRemove.length > 0) {
            for (let i = 0; i < relationshipsToRemove.length; i++) {
                const relation = relationshipsToRemove[i];
                await ContactService.RemoveFile(relation);
            }
            this.setState({ relationshipsToRemove: []})
        }
        
        this.popMessage(saveContactResponse.message, true);
    }

    popMessage = (message: string, success: boolean) => {
        this.setState({
            success: success ? message : undefined,
            error: !success ? message : undefined
        });

        if (success)
            setTimeout(() => { this.setState({ success: undefined, }) }, 1500)
    }

    render() {
        let { classes } = this.props
        const { id } = this.state;

        if (id === 0)
            return <PageLoader />

        return (
            <>
                <Grid container justify="center" style={{ paddingTop: 15 }}>
                    <Grid item md={11}>
                        <Grid container justify="center" spacing={32} style={{ marginBottom: 30 }} >
                            {/* <Grid item md={5}>
                                <ContactPhoneList phoneNumbers={this.state.phoneNumbers} />
                            </Grid> */}
                            <Grid item md={7}>
                                <ContactFileList
                                    fileRelationships={this.state.fileRelationships}
                                    onFileSelected={this.onFileSelected}
                                    onNewFileClick={() => this.props.history.push(`/add-file/${this.state.id}`)}
                                    onExistingFileClick={() => this.setState({ addExistingFileDialog: true })}
                                    onFileRemoved={this.onRelationshipRemoved}
                                />
                            </Grid>
                        </Grid>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" align="center">Contact Info</Typography>
                            <form onSubmit={e => { e.preventDefault(); this.onSaveContact(); }} >
                                <TextField
                                    label="Name"
                                    className={classes.textField}
                                    value={this.state.name}
                                    onChange={e => this.setState({ name: e.target.value })}
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    label="Email"
                                    className={classes.textField}
                                    value={this.state.email}
                                    onChange={e => this.setState({ email: e.target.value })}
                                    margin="normal"
                                    type="email"
                                />
                                <br />
                                <TextField
                                    label="Street"
                                    className={classes.textField}
                                    value={this.state.street}
                                    onChange={e => this.setState({ street: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    label="City"
                                    className={classes.textField}
                                    value={this.state.city}
                                    onChange={e => this.setState({ city: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    label="State"
                                    className={classes.textField}
                                    value={this.state.state}
                                    onChange={e => this.setState({ state: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    label="Zip"
                                    className={classes.textField}
                                    value={this.state.zip !== undefined ? this.state.zip : ""}
                                    onChange={e => this.setState({ zip: parseInt(e.target.value) })}
                                    margin="normal"
                                    type="number"

                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={this.state.deceased}
                                            onChange={e => this.setState({ deceased: e.target.checked })}
                                        />
                                    }
                                    label="Is Deceased"
                                    style={{ paddingTop: 30 }}
                                />
                                <Grid container justify="center">
                                    <Grid item>
                                        <Button variant="contained" color="primary" style={{ margin: 10 }} className={classes.saveButton} type="submit">Save Changes</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
                <SuccessSnackbar
                    successMessage={this.state.success}
                    onClose={() => this.setState({ success: undefined })}
                />
                <ErrorSnackbar
                    errorMessage={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <SearchFileDialog
                    open={this.state.addExistingFileDialog}
                    onClose={() => this.setState({ addExistingFileDialog: false })}
                    onNewRelationSelected={this.onNewRelationToExistingFile}
                />
            </>
        );
    }
}

export default withRoot(withStyles(styles)(ContactDetails));

interface ContactPhoneListProps {
    phoneNumbers: Phone[]
}

const ContactPhoneList: React.SFC<ContactPhoneListProps> = props => {
    return (
        <Paper style={{ padding: 10, margin: 5 }}>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="h6" align="center">Phone Numbers</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.phoneNumbers.length === 0
                                    ?
                                    <TableRow>
                                        <TableCell>No Phone Numbers</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    :
                                    props.phoneNumbers.map(phone => {
                                        return (
                                            <TableRow key={phone.id}>
                                                <TableCell>{phone.number}</TableCell>
                                                <TableCell>{phone.type}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" size="small" style={{ margin: 10 }}>
                        {props.phoneNumbers.length === 0 ? "Add" : "Change"}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

interface ContactFileListProps {
    fileRelationships: ContactFileRelationship[];
    onFileSelected: (fileId: number) => void;
    onNewFileClick: () => void;
    onExistingFileClick: () => void;
    onFileRemoved: (file: ContactFileRelationship) => void;
}

const ContactFileList: React.SFC<ContactFileListProps> = props => {
    return (
        <Paper style={{ margin: 5, padding: 10 }}>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="h6" align="center">Files</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File Number</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Relation</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.fileRelationships.length === 0
                                    ?
                                    <TableRow>
                                        <TableCell>No Files Found</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    :
                                    props.fileRelationships.map(file => {
                                        return (
                                            <>
                                                <TableRow key={file.id}>
                                                    <TableCell>
                                                        <a
                                                            href={`/file/${file.fileNumber}`}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                props.onFileSelected(file.detailsId);
                                                                return false;
                                                            }}
                                                        >
                                                            {file.fileNumber}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell>{file.type}</TableCell>
                                                    <TableCell>{file.relation}</TableCell>
                                                    <TableCell>{file.isClosed ? "Closed" : "Open"}</TableCell>
                                                    <TableCell>
                                                        <a
                                                            href="remove"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                props.onFileRemoved(file)
                                                                return false;
                                                            }}
                                                        >
                                                            Remove
                                                        </a>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ margin: 10 }}
                        onClick={props.onNewFileClick}
                    >
                        New File
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ margin: 10 }}
                        onClick={props.onExistingFileClick}
                    >
                        Link To Existing File
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}