import * as React from 'react';
import withStyles, { WithStyles, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import Search from '../../node_modules/@material-ui/icons/Search';
import Grid from '../../node_modules/@material-ui/core/Grid';
import Typography from '../../node_modules/@material-ui/core/Typography';
import TextField from '../../node_modules/@material-ui/core/TextField';
import InputAdornment from '../../node_modules/@material-ui/core/InputAdornment';
import { Contact, ContactService } from '../services/ContactService';
import { Server } from '../utilities/WebClient';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';


const styles: StyleRulesCallback<any> = theme => ({
    root: {}
});

interface ContactSearchProps extends WithStyles<any> {
    onContactSelect: (contact: Contact) => void
}
interface ContactSearchState {
    contacts: Contact[]
    searchTerm: string
    submittedTerm: string
    searching: boolean
}

class ContactSearch extends React.Component<ContactSearchProps, ContactSearchState> {
    constructor(props: ContactSearchProps) {
        super(props)
        this.state = {
            contacts: [],
            searchTerm: "",
            submittedTerm: "",
            searching: false
        }
    }

    searchContacts = () => {
        this.setState({searching: true})
        ContactService.GetContactsByName(this.state.searchTerm)
        .then(result => {
            if(Server.response(result))
                this.setState({contacts: result.data.contacts, submittedTerm: this.state.searchTerm, searching: false})

            else
                console.error(result)
        })
    }

    render() {
        const classes = this.props.classes;
        return(
            <div>
                <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyStateContainer}>
                        <Typography variant="h6" className={classes.emptyStateTitle}>Search Contacts</Typography>
                        <Typography variant="subtitle1">Search all contacts by name.</Typography>
                        <Grid item>
                            <form onSubmit={e => {e.preventDefault(); this.searchContacts()}}>
                                <TextField style={{width:400}}
                                        onChange={e => this.setState({searchTerm: e.target.value})}
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <Search />
                                            </InputAdornment>
                                        ),
                                        }}
                                    />
                            </form>
                        </Grid>
                </Grid>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item xs={10}>
                        <Paper style={{marginTop: 40, minWidth:400}}>
                            <Table className={classes.table}>
                                <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    this.state.submittedTerm !== "" && this.state.contacts.length === 0 
                                    ?
                                    <TableRow>
                                        <TableCell>No Results</TableCell>
                                    </TableRow>
                                    :
                                    this.state.searching 
                                    ?
                                    <TableRow>
                                        <TableCell>Searching...</TableCell>
                                    </TableRow>
                                    :
                                    this.state.contacts.map(contact => {
                                    return (
                                    <TableRow key={contact.id} hover style={{cursor: "pointer"}} onClick={() => this.props.onContactSelect(contact)}>
                                        <TableCell>{contact.name}</TableCell>
                                    </TableRow>
                                    );
                                })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withRoot(withStyles(styles)(ContactSearch));