import * as React from 'react';
import withStyles, { WithStyles, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import GroupIcon from '@material-ui/icons/Group';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FindInPage from '@material-ui/icons/FindInPage';
import History from '@material-ui/icons/History';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { koehlerFirmLogo } from '../images/images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import { AppConfig } from '../AppConfig';

const drawerWidth = 240;

const styles: StyleRulesCallback<any> = theme => ({
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
    },
    appBarIcon: {
        width: '36px',
        height: '36px'
    },
    drawerPaper: {
        position: 'fixed',
        width: drawerWidth,
    },
    logoImage: {
        height: '48px',
        width: "48px"
    },
    toolbar: theme.mixins.toolbar,
    flex: {
        flexGrow: 1
    },
    contentContainer: {
        height: "100vh",
        paddingLeft: 240,
        paddingTop: 64
    }
});


interface Props extends WithStyles<any> {
    children?: React.ReactNode
}
interface State {
    anchorEl: any,
    userMenuOpen: boolean
}

class Layout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            anchorEl: null,
            userMenuOpen: false
        }
    }


    handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget,
            userMenuOpen: !this.state.userMenuOpen
        })
    }

    handleLogoutClick = () => {
        location.href = `${AppConfig.ApiServer}/auth/logout`;
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>The Koehler Firm</Typography>
                        <IconButton color="inherit" onClick={event => this.handleUserMenuClick(event)}>
                            <AccountCircle className={classes.appBarIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Grid container direction="row" justify="center" alignItems="center" className={classes.toolbar}>
                        <img src={koehlerFirmLogo} className={classes.logoImage} />
                    </Grid>
                    <Divider />
                    <List>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <ListItem button>
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="Search Contacts" />
                            </ListItem>
                        </Link>
                        <Link to="/add-contact" style={{ textDecoration: 'none' }}>
                            <ListItem button>
                                <ListItemIcon>
                                    <PersonAddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Add Contact" />
                            </ListItem>
                        </Link>
                        <Link to="/file/search" style={{ textDecoration: 'none' }}>
                            <ListItem button>
                                <ListItemIcon>
                                    <FindInPage />
                                </ListItemIcon>
                                <ListItemText primary="Search Files" />
                            </ListItem>
                        </Link>
                        <Link to="/history" style={{ textDecoration: 'none' }}>
                            <ListItem button>
                                <ListItemIcon>
                                    <History />
                                </ListItemIcon>
                                <ListItemText primary="History" />
                            </ListItem>
                        </Link>
                    </List>
                </Drawer>
                <Popover
                    open={this.state.userMenuOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({
                        anchorEl: null,
                        userMenuOpen: false
                    })}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <List dense>
                        <ListItem button onClick={this.handleLogoutClick}>
                            <ListItemText primary="Log Out" />
                        </ListItem>
                    </List>
                </Popover>
                <div className={classes.contentContainer}>
                    {this.props.children}
                </div>
            </>
        )
    }
}

export default withRoot(withStyles(styles)(Layout));