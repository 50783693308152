import axios, { AxiosResponse, AxiosError } from 'axios';
import { AppConfig } from '../AppConfig';

export class WebClient {
    static async Get<T>(url: string) {
        return axios.get(url, { withCredentials: true })
            .then((response: AxiosResponse) => {
                return response.data as (ServerResponse<T>);
            }, (errorResult) => {
                return serializeAxiosError((errorResult));
            });
    }

    static async Post<T>(url: string, data: any) {
        return axios(url,
            {
                method: "post",
                data: data,
                withCredentials: true
            })
            .then((response: AxiosResponse) => {
                return response.data as (ServerResponse<T>);
            }, (errorResult) => {
                return serializeAxiosError(errorResult);
            });
    }

    static async Put<T>(url: string, data: any) {
        return axios(url,
            {
                method: "put",
                data: data,
                withCredentials: true
            })
            .then((response: AxiosResponse) => {
                return response.data as (ServerResponse<T>);
            }, (errorResult) => {
                return serializeAxiosError(errorResult);
            });
    }

    static async Delete<T>(url: string, data: any) {
        return axios(url,
            {
                method: "delete",
                data: data,
                withCredentials: true
            })
            .then((response: AxiosResponse) => {
                return response.data as (ServerResponse<T>);
            }, (errorResult) => {
                return serializeAxiosError(errorResult);
            });
    }

}
const serializeAxiosError = (errorResponse: AxiosError) => {
    let response = errorResponse.response;

    // Happens if the http request gets killed mid request
    if (!response)
        return { message: "Received no response", statusCode: 500 } as ServerError;

    switch (response.status) {
        case 404:
            return { message: "Server is not responding", statusCode: response.status };
        case 401:
        case 301:
            return redirectToLogin(response);
        default:
            let standardServerResponse: ServerResponse<string> = response.data;
            return { message: standardServerResponse.message, statusCode: response.status };
    }
};


export interface ServerResponse<T> {
    data: T,
    message: string
}

export interface ServerError {
    message: string,
    statusCode: number
}

export class Server {
    static response<T>(serverResponse: ServerResponse<T> | ServerError): serverResponse is ServerResponse<T> {
        let model = serverResponse as any;
        return model.data !== undefined && model.message !== undefined
    }
}


function redirectToLogin(response: AxiosResponse<any>) {
    let path = window.location.pathname;
    let redirect = "?returnUrl=" + encodeURIComponent(path);
    window.location.replace(`/sign-in` + redirect);
    let standardServerResponse: ServerResponse<string> = response.data;
    let serverError: ServerError = { message: standardServerResponse.message, statusCode: response.status };
    return serverError;
}

