import * as React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const PageLoader: React.SFC<{}> = () => {
    return (
        <Grid container justify="center" alignItems="center" style={{ height: "calc(100% - 64px)" }}>
            <Grid item>
                <CircularProgress size={75} />
            </Grid>
        </Grid>
    )
}

export default PageLoader;