import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import withRoot from "../../withRoot";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FileSearch from "../../components/FileSearch";
import { FileDetails } from "../../services/FileService";


const styles = (theme: Theme) => (
    createStyles({
    }));

interface Props extends WithStyles<typeof styles> {
    open: boolean
    onClose: () => void
    onNewRelationSelected: (file: FileDetails, relation: string) => void
}
interface State {
    relation: string
}


class SearchFileDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            relation: "Client"
        }
    }

    onFileSelect = (file: FileDetails) => {
        const { relation } = this.state;
        this.props.onNewRelationSelected(file, relation)
        this.props.onClose()
    }

    render() {

        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} fullWidth>
                <div style={{ padding: 10 }}>
                    <Typography variant="h5" align="center" style={{ padding: 10 }}>Add Existing File</Typography>
                    <Grid container direction="column" alignContent="center">
                        <Grid item>
                            <FormControl style={{ minWidth: 400 }}>
                                <InputLabel htmlFor="Relation">Contact Relation to File</InputLabel>
                                <Select
                                    value={this.state.relation}
                                    onChange={e => this.setState({ relation: e.target.value })}
                                    inputProps={{
                                        id: 'relation',
                                    }}
                                >
                                    <MenuItem value="Client">
                                        Client
                                    </MenuItem>
                                    <MenuItem value="Adversary">
                                        Adversary
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <FileSearch onFileSelect={this.onFileSelect} />
                </div>
            </Dialog>
        );
    }
}

export default withRoot(withStyles(styles)(SearchFileDialog));