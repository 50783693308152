import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { ContactService, Contact } from '../services/ContactService';
import { RouteComponentProps } from 'react-router-dom';
import { Server } from '../utilities/WebClient';
import Typography from '@material-ui/core/Typography';
import { isNullEmptyOrWhitespace } from '../utilities/HelperFunctions';
import { FileDetails, FileService } from '../services/FileService';
import PageLoader from '../components/PageLoader';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorSnackbar from '../components/ErrorSnackbar';

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1000,
            padding: 20,
            marginLeft: "auto",
            marginRight: "auto"
        },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
            width: 200,
        },
    });

interface Params {
    fileId?: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<Params> { }
interface State {
    loading: boolean
    name: string
    street: string
    city: string
    state: string
    zip?: number
    email: string
    deceased: boolean
    fileDetail?: FileDetails
    relation: string
    submitInProgress: boolean
    error?: string
    success?: string
}

class AddContact extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            street: "",
            city: "",
            state: "",
            email: "",
            deceased: false,
            relation: "Client",
            submitInProgress: false
        }
    }

    async componentDidMount() {
        const fileIdString = this.props.match.params.fileId;
        if (fileIdString == null) {
            this.setState({ loading: false });
            return
        }
        const fileId = parseInt(fileIdString, 10)

        let response = await FileService.GetFileDetail(fileId);
        if (Server.response(response)) {
            this.setState({ loading: false, fileDetail: response.data });
        } else {
            this.setState({ loading: false });
        }
    }

    onAddContact = async () => {
        const { name, street, city, state, zip, email, deceased, submitInProgress, fileDetail, relation } = this.state;
        if (submitInProgress) {
            return;
        }
        if (isNullEmptyOrWhitespace(name)) {
            this.setState({ error: "Cannot add contact without name" })
            return;
        }
        this.setState({ error: undefined, submitInProgress: true })

        let contact: Contact = {
            id: 0,
            name,
            street: isNullEmptyOrWhitespace(street) ? undefined : street,
            city: isNullEmptyOrWhitespace(city) ? undefined : city,
            state: isNullEmptyOrWhitespace(state) ? undefined : state,
            zip: zip ? zip : 0,
            email: isNullEmptyOrWhitespace(email) ? undefined : email,
            deceased
        }

        if (fileDetail) {
            let response = await FileService.CreateContact(contact, fileDetail, relation);

            if (Server.response(response))
                this.props.history.replace(`/file/${fileDetail.id}`)
            else
                this.setState({ error: response.message, submitInProgress: false })
        } else {
            let response = await ContactService.AddContact(contact);

            if (Server.response(response))
                this.props.history.replace(`/contact/${response.data.id}`)
            else
                this.setState({ error: response.message, submitInProgress: false })
        }
    }

    render() {
        const { submitInProgress, fileDetail, loading } = this.state;
        const { classes } = this.props;

        if (loading)
            return <PageLoader />

        return (
            <>
                <Grid container direction="column" justify="center" alignItems="stretch" style={{ paddingTop: 15 }}>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <Typography variant="h5" align="center" >Add Contact {fileDetail && `to ${fileDetail.fileNumber}`}</Typography>
                            <form onSubmit={e => { e.preventDefault(); this.onAddContact(); }}>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item>
                                        {
                                            fileDetail
                                            &&
                                            <>
                                                <FormControl style={{ minWidth: 400 }}>
                                                    <InputLabel htmlFor="Relation">Relation to File {fileDetail.fileNumber}</InputLabel>
                                                    <Select
                                                        value={this.state.relation}
                                                        onChange={e => this.setState({ relation: e.target.value })}
                                                        inputProps={{
                                                            id: 'relation',
                                                        }}
                                                    >
                                                        <MenuItem value="Client">
                                                            Client
                                                        </MenuItem>
                                                        <MenuItem value="Adversary">
                                                            Adversary
                                                    </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <br />
                                            </>
                                        }
                                        <TextField
                                            label="Name"
                                            className={classes.textField}
                                            value={this.state.name}
                                            onChange={e => this.setState({ name: e.target.value })}
                                            margin="normal"
                                            required
                                            disabled={submitInProgress}
                                        />
                                        <TextField
                                            label="Email"
                                            className={classes.textField}
                                            value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value })}
                                            margin="normal"
                                            type="email"
                                            disabled={submitInProgress}
                                        />
                                        <br />
                                        <TextField
                                            label="Street"
                                            className={classes.textField}
                                            value={this.state.street}
                                            onChange={e => this.setState({ street: e.target.value })}
                                            margin="normal"
                                            disabled={submitInProgress}
                                        />
                                        <TextField
                                            label="City"
                                            className={classes.textField}
                                            value={this.state.city}
                                            onChange={e => this.setState({ city: e.target.value })}
                                            margin="normal"
                                            disabled={submitInProgress}
                                        />
                                        <TextField
                                            label="State"
                                            className={classes.textField}
                                            value={this.state.state}
                                            onChange={e => this.setState({ state: e.target.value })}
                                            margin="normal"
                                            disabled={submitInProgress}
                                        />
                                        <TextField
                                            label="Zip"
                                            className={classes.textField}
                                            value={this.state.zip !== undefined ? this.state.zip : ""}
                                            onChange={e => this.setState({ zip: parseInt(e.target.value) })}
                                            margin="normal"
                                            type="number"
                                            disabled={submitInProgress}

                                        />
                                        <br />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.deceased}
                                                    onChange={e => this.setState({ deceased: e.target.checked })}
                                                />
                                            }
                                            label="Is Deceased"
                                            disabled={submitInProgress}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ margin: 10 }}
                                            type="submit"
                                            disabled={submitInProgress}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
                <ErrorSnackbar
                    errorMessage={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
            </>
        );
    }
}

export default withRoot(withStyles(styles)(AddContact));