

import * as React from 'react';
import withStyles, { WithStyles, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { RouteComponentProps } from 'react-router';
import ContactSearch from '../components/ContactSearch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { AppConfig } from '../AppConfig';
import { Typography } from '@material-ui/core';


const styles: StyleRulesCallback<any> = theme => ({
    logInPaper: {
		paddingTop: 15,
		paddingBottom: 30,
		paddingLeft: 30,
		paddingRight: 30,
		margin: 8,
		maxWidth: 450,
	},
});

interface LoginProps extends RouteComponentProps<void>, WithStyles<any> {}
interface LoginState {
}

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        return(
            <Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Paper className={this.props.classes.logInPaper}>
                    <Grid container direction="column" justify="center" alignItems="center">
						<Typography align="center" variant="h4">Sign In</Typography>
					</Grid>
					<form method="post" action={`${AppConfig.ApiServer}/auth/login`}>
                        <input type="hidden" name="redirect" value={window.location.origin} />
						<TextField
							label="Email"
                            name="email"
							margin="normal"
							fullWidth
							required
							type="email"
						/>
						<TextField
							label="Password"
                            name="password"
							margin="normal"
							fullWidth
							required
							type="password"
						/>
						<Hidden smDown>
							<Grid container direction="row" justify="space-between" alignItems="center" style={{ paddingTop: 30 }}>
								<Button id="login-button" variant="contained" size="medium" color="primary" type="submit" style={{ minWidth: 150 }}>
									Sign In
								</Button>
							</Grid>
						</Hidden>
						<Hidden mdUp>
							<Button id="login-button" fullWidth variant="contained" size="medium" color="primary" type="submit" style={{ marginTop: 20 }}>
								Sign In
							</Button>
						</Hidden>
					</form>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withRoot(withStyles(styles)(Login));