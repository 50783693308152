import * as React from 'react';
import withStyles, { WithStyles, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { RouteComponentProps } from 'react-router';
import { FileDetails } from '../services/FileService';
import FileSearch from '../components/FileSearch';


const styles: StyleRulesCallback<any> = theme => ({
    root: {}
});

interface SearchFilesProps extends RouteComponentProps<void>, WithStyles<any> {}
interface SearchFilesState {
    files: FileDetails[]
    searchTerm: string
    submittedTerm: string
    searching: boolean
}

class SearchFiles extends React.Component<SearchFilesProps, SearchFilesState> {
    constructor(props: SearchFilesProps) {
        super(props)
        this.state = {
            files: [],
            searchTerm: "",
            submittedTerm: "",
            searching: false
        }
    }

    onFileSelect = (file: FileDetails) => {
        this.props.history.push(`/file/${file.id}`)
    }

    render() {
        return(
            <div style={{paddingTop: 15}}>
                <FileSearch onFileSelect={ this.onFileSelect }/>
            </div>
        )
    }
}

export default withRoot(withStyles(styles)(SearchFiles));