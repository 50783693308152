import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import withRoot from "../../withRoot";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { DateUtil } from "../../utilities/DateUtil";
import { FileDetails } from "../../services/FileService";
import { isNullEmptyOrWhitespace } from "../../utilities/HelperFunctions";


const styles = (theme: Theme) => (
    createStyles({
        form: {
            padding: 20
        },
        buttonContainer: {
            paddingTop: 10
        }
    }));

export interface OnCloseFileResponse {
    openDate: Date
    closeDate?: Date
    disposalStatus?: string
    disposalDate?: Date
    shredded: boolean
    fileDocsRetained: boolean
    judgementDocsRetained: boolean
    receiptReleaseDocsRetained: boolean
    otherDocsRetained: boolean
    otherRetainedDocDescription?: string
}

interface Props extends WithStyles<typeof styles> {
    open: boolean
    fileDetails: FileDetails
    onClose: () => void
    onSave: (response: OnCloseFileResponse) => void
}
interface State {
    openDate: string
    closed: boolean
    closeDate?: string
    disposed: boolean
    disposalStatus: string
    disposalDate: string
    shredded: boolean
    fileDocsRetained: boolean
    judgementDocsRetained: boolean
    receiptReleaseDocsRetained: boolean
    otherDocsRetained: boolean
    otherRetainedDocDescription: string
}

class CloseFileDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let file = props.fileDetails;

        this.state = {
            openDate: DateUtil.toInputFormat(file.opened),
            closed: !!file.closed,
            closeDate: file.closed ? DateUtil.toInputFormat(file.closed) : undefined,
            disposed: !!file.disposedDate,
            disposalStatus: file.disposalStatus ? file.disposalStatus : "",
            disposalDate: file.disposedDate ? DateUtil.toInputFormat(file.disposedDate) : DateUtil.toInputFormat(new Date()),
            shredded: file.shredded,
            fileDocsRetained: file.fileDocsRetained,
            judgementDocsRetained: file.judgementDocsRetained,
            receiptReleaseDocsRetained: file.receiptReleaseDocsRetained,
            otherDocsRetained: file.otherDocsRetained,
            otherRetainedDocDescription: file.otherRetainedDocDescription ? file.otherRetainedDocDescription : ""
        }
    }

    onSave = () => {
        let {
            openDate, closed, closeDate,
            disposed, disposalStatus, disposalDate, shredded, fileDocsRetained,
            judgementDocsRetained, receiptReleaseDocsRetained,
            otherDocsRetained, otherRetainedDocDescription
        } = this.state

        if (closed && closeDate == null)
            closeDate = DateUtil.toInputFormat(new Date());
        if (!closed && closeDate != null)
            closeDate = undefined;

        let response: OnCloseFileResponse = {
            openDate: DateUtil.inputFormatToDate(openDate),
            closeDate: closeDate ? DateUtil.inputFormatToDate(closeDate) : undefined,
            disposalStatus: isNullEmptyOrWhitespace(disposalStatus) ? undefined : disposalStatus,
            disposalDate: disposed ? DateUtil.inputFormatToDate(disposalDate) : undefined,
            shredded: disposed ? shredded : false,
            fileDocsRetained: disposed ? fileDocsRetained : false,
            judgementDocsRetained: disposed ? judgementDocsRetained : false,
            receiptReleaseDocsRetained: disposed ? receiptReleaseDocsRetained : false,
            otherDocsRetained: disposed ? otherDocsRetained : false,
            otherRetainedDocDescription: isNullEmptyOrWhitespace(otherRetainedDocDescription) ? undefined : otherRetainedDocDescription
        }

        this.props.onSave(response);
    }

    render() {
        const { classes } = this.props

        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} >
                <form className={classes.form} onSubmit={e => { e.preventDefault(); this.onSave(); }}>
                    <Typography variant="h5" align="center">Change Status</Typography>
                    <Typography variant="body2" align="center">{this.props.fileDetails.fileNumber} ({this.props.fileDetails.type})</Typography>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Open Date"
                        required
                        value={this.state.openDate}
                        onChange={e => this.setState({ openDate: e.target.value })}
                        type="date"
                        fullWidth
                    />
                    <div style={{ paddingTop: 5 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.closed}
                                    onChange={e => this.setState({ closed: e.target.checked })}
                                />
                            }
                            label="Closed"
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label="Close Date"
                            required={this.state.closed}
                            disabled={!this.state.closed}
                            defaultValue={DateUtil.toInputFormat(new Date())}
                            value={this.state.closeDate}
                            onChange={e => this.setState({ closeDate: e.target.value })}
                            type="date"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.disposed}
                                    onChange={e => this.setState({ disposed: e.target.checked })}
                                />
                            }
                            label="Disposed"
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label="Disposal Date"
                            required={this.state.disposed}
                            disabled={!this.state.disposed}
                            value={this.state.disposalDate}
                            onChange={e => this.setState({ disposalDate: e.target.value })}
                            type="date"
                        />
                    </div>
                    <TextField
                        label="Disposal Status"
                        disabled={!this.state.disposed}
                        value={this.state.disposalStatus}
                        onChange={e => this.setState({ disposalStatus: e.target.value })}
                        multiline
                        fullWidth
                    />
                    <FormControlLabel
                        disabled={!this.state.disposed}
                        control={
                            <Checkbox
                                checked={this.state.shredded}
                                onChange={e => this.setState({ shredded: e.target.checked })}
                            />
                        }
                        label="Shredded"
                    />
                    <br />
                    <FormControlLabel
                        disabled={!this.state.disposed}
                        control={
                            <Checkbox
                                checked={this.state.fileDocsRetained}
                                onChange={e => this.setState({ fileDocsRetained: e.target.checked })}
                            />
                        }
                        label="File Documents Retained"
                    />
                    <br />
                    <FormControlLabel
                        disabled={!this.state.disposed}
                        control={
                            <Checkbox
                                checked={this.state.judgementDocsRetained}
                                onChange={e => this.setState({ judgementDocsRetained: e.target.checked })}
                            />
                        }
                        label="Judgement Documents Retained"
                    />
                    <br />
                    <FormControlLabel
                        disabled={!this.state.disposed}
                        control={
                            <Checkbox
                                checked={this.state.receiptReleaseDocsRetained}
                                onChange={e => this.setState({ receiptReleaseDocsRetained: e.target.checked })}
                            />
                        }
                        label="Receipt Release Documents Retained"
                    />
                    <br />
                    <FormControlLabel
                        disabled={!this.state.disposed}
                        control={
                            <Checkbox
                                checked={this.state.otherDocsRetained}
                                onChange={e => this.setState({ otherDocsRetained: e.target.checked })}
                            />
                        }
                        label="Other Documents Retained"
                    />
                    <br />
                    <TextField
                        label="Other Document Description"
                        disabled={!this.state.otherDocsRetained}
                        value={this.state.otherRetainedDocDescription}
                        onChange={e => this.setState({ otherRetainedDocDescription: e.target.value })}
                        multiline
                        fullWidth
                    />
                    <Grid container justify="flex-end" className={classes.buttonContainer} >
                        <Grid item>
                            <Button variant="text" onClick={this.props.onClose}>Cancel</Button>
                            <Button variant="contained" color="primary" type="submit">Save</Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        );
    }
}

export default withRoot(withStyles(styles)(CloseFileDialog));