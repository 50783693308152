import * as React from 'react';
import withStyles, { WithStyles, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import Search from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Server } from '../utilities/WebClient';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { FileDetails, FileService } from '../services/FileService';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';


const styles: StyleRulesCallback<any> = theme => ({
    root: {}
});

interface FileSearchProps extends WithStyles<any> {
    onFileSelect: (file: FileDetails) => void
}
interface FileSearchState {
    files: FileDetails[]
    searchTerm: string
    byFileNumber: boolean
    submittedTerm: string
    searching: boolean
}

class FileSearch extends React.Component<FileSearchProps, FileSearchState> {
    constructor(props: FileSearchProps) {
        super(props)
        this.state = {
            files: [],
            searchTerm: "",
            byFileNumber: true,
            submittedTerm: "",
            searching: false
        }
    }

    searchFiles = () => {
        this.setState({ searching: true })
        FileService.SearchFileDetail(this.state.searchTerm, this.state.byFileNumber)
            .then(result => {
                if (Server.response(result))
                    this.setState({ files: result.data, submittedTerm: this.state.searchTerm, searching: false })
                else
                    console.error(result)
            })
    }

    onSearchTypeChange = () => {
        this.setState({byFileNumber: !this.state.byFileNumber}, () => {
            if (this.state.searchTerm.trim().length > 0) {
                this.searchFiles();
            }
        });
    }

    render() {
        const { classes, onFileSelect } = this.props;
        return (
            <div>
                <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyStateContainer}>
                    <Typography variant="h6" className={classes.emptyStateTitle}>Search Files</Typography>
                    <Typography variant="subtitle1">Search all files by file {this.state.byFileNumber ? "number" : "name"}.</Typography>
                    <FormControl className={classes.formControl}>
                        <RadioGroup
                            aria-label="Gender"
                            name="gender1"
                            row={true}
                            className={classes.group}
                            value={`${this.state.byFileNumber}`}
                            onChange={this.onSearchTypeChange}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="File Number" />
                            <FormControlLabel value="false" control={<Radio />} label="File Name" />
                        </RadioGroup>
                    </FormControl>
                    <Grid item>
                        <form onSubmit={e => { e.preventDefault(); this.searchFiles() }}>
                            <TextField style={{ width: 400 }}
                                onChange={e => this.setState({ searchTerm: e.target.value })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </form>
                    </Grid>
                </Grid>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item xs={10}>
                        <Paper style={{ marginTop: 40, minWidth: 400 }}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>File Number</TableCell>
                                        <TableCell>File Name</TableCell>
                                        <TableCell>Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.submittedTerm !== "" && this.state.files.length === 0
                                            ?
                                            <TableRow>
                                                <TableCell>No Results</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            :
                                            this.state.searching
                                                ?
                                                <TableRow>
                                                    <TableCell>Searching...</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                                :
                                                this.state.files.map(file =>
                                                    <TableRow key={file.id} hover style={{ cursor: "pointer" }} onClick={() => onFileSelect(file)}>
                                                        <TableCell>{file.fileNumber}</TableCell>
                                                        <TableCell>{file.name}</TableCell>
                                                        <TableCell>{file.type}</TableCell>
                                                    </TableRow>
                                                )}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withRoot(withStyles(styles)(FileSearch));