import * as React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import { AuthService } from "./services/AuthService";
import Layout from "./pages/Layout";
import ContactDetail from "./pages/contact-detail/ContactDetail";
import FileDetail from "./pages/file-detail/FileDetail";
import AddContact from "./pages/AddContact";
import AddFile from "./pages/AddFile";
import { AppConfig } from "./AppConfig";
import { Server } from "./utilities/WebClient";
import SearchFiles from "./pages/SearchFiles";
import History from "./pages/History";
import Login from "./pages/Login";

interface AppProps {}
interface AppState {
  loggedIn?: boolean;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let result = await AuthService.AuthCheck();
    debugger;
    if (Server.response(result)) {
      this.setState({ loggedIn: true });
    } else {
      this.setState({ loggedIn: false });
    }
  }

  render() {
    const loggedIn = this.state.loggedIn;
    if (loggedIn == null) return <h2>Checking Credentials...</h2>;
    if (loggedIn == false) return <BrowserRouter><Route component={Login} /></BrowserRouter>;

    return (
      <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/contact/:id" component={ContactDetail} />
              <Route path="/add-contact/:fileId" component={AddContact} />
              <Route path="/add-contact" component={AddContact} />
              <Route path="/add-file/:contactId" component={AddFile} />
              <Route path="/file/search" component={SearchFiles} />
              <Route path="/file/:id" component={FileDetail} />
              <Route path="/history" component={History} />
              <Redirect to="/" />
            </Switch>
          </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
