
import { AppConfig } from '../AppConfig';
import { WebClient } from '../utilities/WebClient';
import { ContactFileRelationship, FileDetails } from './FileService';

export interface Contact {
    id: number
    name: string
    street?: string
    city?: string
    state?: string
    zip: number
    email?: string
    deceased: boolean
}

interface AddContactRequest {
    name: string
    street?: string
    city?: string
    state?: string
    zip?: number
    email?: string
    deceased: boolean
}

interface GetContactsByNameResponse {
    contacts: Contact[]
}

interface GetContactResponse {
    contact: Contact
}

interface AddFileRequest {
    fileNumber: string;
    name: string;
    type: string;
    relation: string;
}

export class ContactService {
    static async GetContactsByName(name : string)  {
        let endpoint = AppConfig.ApiServer + "/Contacts/GetByName?name=" + encodeURI(name);
        return WebClient.Get<GetContactsByNameResponse>(endpoint);
    }

    static async GetById(contactId: number) {
        let endpoint = AppConfig.ApiServer + "/contacts/get/" + contactId;
        return WebClient.Get<GetContactResponse>(endpoint);
    }

    static async SaveContact(contact: Contact) {
        let endpoint = AppConfig.ApiServer + "/contacts/update";
        return WebClient.Post<{}>(endpoint, contact);
    }

    static async AddContact(request: AddContactRequest) {
        let endpoint = AppConfig.ApiServer + "/contacts/";
        return WebClient.Post<Contact>(endpoint, request);
    }

    static async AddFile(contact: Contact, request: AddFileRequest) {
        let requestObject = {...request, contactId: contact.id};
        let endpoint = AppConfig.ApiServer + "/contacts/file";
        return WebClient.Post<{fileDetailId: number}>(endpoint, requestObject);
    }

    static async RemoveFile(relationship: ContactFileRelationship)
    {
        let endpoint = `${AppConfig.ApiServer}/contacts/remove-file`;
        let request = { relationshipId: relationship.id };
        return WebClient.Post<null>(endpoint, request);
    }

    static async AddExistingFile(relationship: ContactFileRelationship) {
        let endpoint = AppConfig.ApiServer + "/contacts/existing-file";
        return WebClient.Post<{fileDetailId: number}>(endpoint, relationship);
    }

    static isContact(obj : Contact | FileDetails | ContactFileRelationship) : obj is Contact{
        let model = obj as any;
        return model.name !== undefined && model.fileNumber == null;
    }
}

