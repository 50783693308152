
import { AppConfig } from '../AppConfig';
import { WebClient } from '../utilities/WebClient';

export interface Phone {
    id: number
    number: string
    type: string
    contactId: number
}

interface GetPhoneResponse {
    phones: Phone[]
}

export class PhoneService {
    static async GetPhonesByContact(contactId: number)  {
        let endpoint = `${AppConfig.ApiServer}/Phone/?contactId=${contactId}`;
        return WebClient.Get<GetPhoneResponse>(endpoint)
    }
}

