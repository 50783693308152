import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ContactService, Contact } from '../services/ContactService';
import { RouteComponentProps } from 'react-router-dom';
import { Server } from '../utilities/WebClient';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { CustomSnackbarContentWrapper } from '../components/CustomSnackbar';
import { isNullEmptyOrWhitespace } from '../utilities/HelperFunctions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PageLoader from '../components/PageLoader';

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 500,
            padding: 20,
            marginLeft: "auto",
            marginRight: "auto"
        },
        textField: {
            width: 200,
        },
    });

interface Params {
    contactId: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<Params> { }
interface State {
    fileNumber: string
    name: string
    type: string
    relation: string
    submitInProgress: boolean
    contact?: Contact
    error?: string
    success?: string
}

class AddFile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let today = new Date();
        this.state = {
            fileNumber: `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()} ${today.getHours()}`,
            name: "",
            type: "",
            relation: "",
            submitInProgress: false
        }
    }

    async componentDidMount() {
        let contactIdParam = this.props.match.params.contactId;
        if (!contactIdParam) {
            this.props.history.replace("/");
            return;
        }
        let contactId = parseInt(contactIdParam);
        if (isNaN(contactId)) {
            this.props.history.replace("/");
            return;
        }

        let response = await ContactService.GetById(contactId);

        if (Server.response(response))
            this.setState({ contact: response.data.contact })
        else
            this.props.history.replace("/");
    }

    onAddFile = async () => {
        const { fileNumber, name, type, relation, contact, submitInProgress } = this.state;
        if (submitInProgress)
            return;

        if (!contact) {
            this.setState({ error: "Contact not set" })
            return;
        }
        if (isNullEmptyOrWhitespace(fileNumber)) {
            this.setState({ error: "Cannot add file without file number" })
            return;
        }
        if (isNullEmptyOrWhitespace(name)) {
            this.setState({ error: "Cannot add file without file name" })
            return;
        }
        if (isNullEmptyOrWhitespace(type)) {
            this.setState({ error: "File type not set" })
            return;
        }
        if (isNullEmptyOrWhitespace(relation)) {
            this.setState({ error: "Relation not set" })
            return;
        }
        this.setState({ submitInProgress: true })

        let response = await ContactService.AddFile(contact, { fileNumber, name, type, relation });

        if (Server.response(response))
            this.props.history.replace(`/file/${response.data.fileDetailId}`)
        else
            this.setState({ error: response.message, submitInProgress: false })
    }

    render() {
        const { classes } = this.props;
        const { contact, type, relation, submitInProgress } = this.state;

        if (!contact)
            return <PageLoader />;

        return (
            <>
                <Grid container direction="column" justify="center" alignItems="stretch" style={{paddingTop: 15}}>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <Typography variant="h5" align="center" >Add File to {contact.name}</Typography>
                            <form onSubmit={e => { e.preventDefault(); this.onAddFile(); }}>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item>
                                        <TextField
                                            label="File Number"
                                            className={classes.textField}
                                            value={this.state.fileNumber}
                                            onChange={e => this.setState({ fileNumber: e.target.value })}
                                            margin="normal"
                                            required
                                            disabled={submitInProgress}
                                        />
                                        <br />
                                        <TextField
                                            label="File Name"
                                            className={classes.textField}
                                            value={this.state.name}
                                            onChange={e => this.setState({ name: e.target.value })}
                                            required
                                            disabled={submitInProgress}
                                        />
                                        <br />
                                        <FormControl required>
                                            <InputLabel htmlFor="file-type">File Type</InputLabel>
                                            <Select
                                                value={type}
                                                className={classes.textField}
                                                onChange={e => this.setState({ type: e.target.value })}
                                                inputProps={{
                                                    id: 'file-type',
                                                }}
                                                disabled={submitInProgress}
                                            >
                                                <MenuItem value="">
                                                    <em>Select Type</em>
                                                </MenuItem>
                                                <MenuItem value='Succession'>Succession</MenuItem>
                                                <MenuItem value='Will Package'>Will Package</MenuItem>
                                                <MenuItem value='Other'>Other</MenuItem>
                                                <MenuItem value='Insurance Defense'>Insurance Defense</MenuItem>
                                                <MenuItem value='LLC Organizations'>LLC Organizations</MenuItem>
                                                <MenuItem value='Commercial'>Commercial</MenuItem>
                                                <MenuItem value='Collection'>Collection</MenuItem>
                                                <MenuItem value='Personal Injury'>Personal Injury</MenuItem>
                                                <MenuItem value='General Advices'>General Advices</MenuItem>
                                                <MenuItem value='Foreclosure'>Foreclosure</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <br />
                                        <FormControl required>
                                            <InputLabel htmlFor="file-relation">Relation</InputLabel>
                                            <Select
                                                value={relation}
                                                className={classes.textField}
                                                onChange={e => this.setState({ relation: e.target.value })}
                                                inputProps={{
                                                    id: 'file-relation',
                                                }}
                                                disabled={submitInProgress}
                                            >
                                                <MenuItem value="">
                                                    <em>Select Relation</em>
                                                </MenuItem>
                                                <MenuItem value='Client'>Client</MenuItem>
                                                <MenuItem value='Adverse'>Adverse</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ margin: 10 }}
                                            type="submit"
                                            disabled={submitInProgress}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
                <Snackbar
                    open={this.state.error != null}
                    onClose={() => this.setState({ error: undefined })}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <CustomSnackbarContentWrapper
                        onClose={() => this.setState({ error: undefined })}
                        variant="error"
                        message={this.state.error}
                    />
                </Snackbar>
            </>
        );
    }
}

export default withRoot(withStyles(styles)(AddFile));