import { AppConfig } from "../AppConfig";
import { WebClient, Server, ServerResponse } from "../utilities/WebClient";
import { Contact, ContactService } from "./ContactService";
import { FileDetails, FileDetailsServerResponse, ContactFileRelationship, FileService } from "./FileService";

export interface ChangeHistory {
    id: number;
    action: string;
    timeOfAction: Date;
    username: string;
    snapshot: Contact | FileDetails | ContactFileRelationship,
    type: ChangeHistoryType
}

export enum ChangeHistoryType {
    Contact = "Contact", File ="File", Relationship="Contact Relation to File"
}

interface ServerResponseHistory {
    id: number;
    action: string;
    timeOfAction: string;
    username: string;
    snapshot: Contact | FileDetailsServerResponse | ContactFileRelationship
}



export class HistoryService {
    static async Search(take: number, since?: Date) {
        let endpoint = AppConfig.ApiServer + `/history?take=${take}`;
        let request = {
            take,
            before: since ? since.toISOString() : undefined
        }
        var serverResponse = await WebClient.Post<ServerResponseHistory[]>(endpoint, request)
        if(Server.response(serverResponse)) {
            let history = serverResponse.data.map(r => HistoryService.HistoryConversion(r));
            let response: ServerResponse<ChangeHistory[]> = {
                ...serverResponse,
                data: history
            }
            return response;
        }
        return serverResponse;
    }

    static HistoryConversion(historyResponse: ServerResponseHistory) {
        let snapshot = 
            HistoryService.isFileResponse(historyResponse.snapshot) ? 
            HistoryService.fileResponseToFileDetails(historyResponse.snapshot) : 
            historyResponse.snapshot;

        let type = ChangeHistoryType.Relationship;
        if(FileService.isFile(snapshot))
            type = ChangeHistoryType.File;
        if(ContactService.isContact(snapshot))
            type = ChangeHistoryType.Contact;

        let history: ChangeHistory = {
            ...historyResponse,
            timeOfAction: new Date(historyResponse.timeOfAction),
            snapshot,
            type
        }
        return history;
    }

    static fileResponseToFileDetails(file: FileDetailsServerResponse) {
        return FileService.ServerFileDetailsToFileDetails(file);
    }

    static isFileResponse(obj : Contact | FileDetailsServerResponse | ContactFileRelationship) : obj is FileDetailsServerResponse{
        let model = obj as any;
        return model.fileNumber !== undefined && model.opened !== undefined
    }
}