import { AppConfig } from "../AppConfig";
import { WebClient } from "../utilities/WebClient";

export interface Court {
    id: number
    name: string
}

export class CourtService {
    static async GetCourts() {
        let endpoint = `${AppConfig.ApiServer}/courts`;
        return WebClient.Get<Court[]>(endpoint)
    }
}