import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../../withRoot';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { Server } from '../../utilities/WebClient';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FileService, FileDetails, ContactFileRelationship } from '../../services/FileService';
import { Theme, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseFileDialog, { OnCloseFileResponse } from './CloseFileDialog';
import CourtInfoDialog, { OnCourtInfoResponse } from './CourtInfoDialog';
import { CourtService } from '../../services/CourtService';
import PageLoader from '../../components/PageLoader';
import SuccessSnackbar from '../../components/SuccessSnackbar';
import ErrorSnackbar from '../../components/ErrorSnackbar';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Contact } from '../../services/ContactService';
import SearchContactDialog from './SearchContactDialog';


const styles = (theme: Theme) => (
    createStyles({
        paper: {
            width: "100%",
            padding: 15
        },
        formField: {
            minWidth: 300,
        },
        sortLines: {
            lineHeight: 1.86
        },
        section: {
            padding: 30
        },
        sectionHeader: {
            paddingBottom: 5
        },
        saveButton: {
            padding: 10,
            paddingLeft: 30,
            paddingRight: 30
        }
    }));

interface Params {
    id: string;
}

interface Props extends RouteComponentProps<Params>, WithStyles<typeof styles> { }
interface State {
    fileDetails?: FileDetails
    contactFileRelationships: ContactFileRelationship[]
    relationshipsToAdd: ContactFileRelationship[]
    relationshipsToRemove: ContactFileRelationship[]
    fileNumberCache: string
    courts: string[]
    closeFileDialog: boolean
    courtInfoDialog: boolean
    contactSearchDialog: boolean
    submitInProgress: boolean
    error?: string
    success?: string
}

class FileDetail extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            contactFileRelationships: [],
            relationshipsToAdd: [],
            relationshipsToRemove: [],
            fileNumberCache: "",
            courts: [],
            submitInProgress: false,
            closeFileDialog: false,
            courtInfoDialog: false,
            contactSearchDialog: false
        }
    }

    componentDidMount() {
        const fileIdParam = this.props.match.params.id;
        if (!fileIdParam)
            this.props.history.replace("/")
        else {
            const fileId = parseInt(fileIdParam);
            FileService.GetFileDetail(fileId)
                .then(response => {
                    if (Server.response(response)) {
                        this.setState({ fileDetails: response.data, fileNumberCache: response.data.fileNumber })
                    }
                    else
                        this.popMessage(response.message, false)
                })
            FileService.GetRelationshipsByFile(fileId)
                .then(response => {
                    if (Server.response(response)) {
                        this.setState({ contactFileRelationships: response.data })
                    }
                    else
                        this.popMessage(response.message, false)
                })
            CourtService.GetCourts().then(response => {
                if (Server.response(response)) {
                    this.setState({ courts: response.data.map(c => c.name) })
                }
                else
                    this.popMessage("Cannot get courts", false)
            })
        }
    }

    onUpdateCloseFile = (response: OnCloseFileResponse) => {
        const { fileDetails } = this.state
        const {
            openDate,
            closeDate,
            disposalStatus,
            disposalDate,
            shredded,
            fileDocsRetained,
            judgementDocsRetained,
            receiptReleaseDocsRetained,
            otherDocsRetained,
            otherRetainedDocDescription } = response

        if (!fileDetails)
            return

        this.setState({
            fileDetails: {
                ...fileDetails,
                opened: openDate,
                closed: closeDate,
                isClosed: !!closeDate,
                disposalStatus,
                disposedDate: disposalDate,
                shredded,
                fileDocsRetained,
                judgementDocsRetained,
                receiptReleaseDocsRetained,
                otherDocsRetained,
                otherRetainedDocDescription
            },
            closeFileDialog: false
        })
    }

    onUpdateCourtInfo = (response: OnCourtInfoResponse) => {
        const { fileDetails } = this.state
        const { suitIsFiled, suitDate, court, docketNumber, courtNotes } = response

        if (!fileDetails)
            return

        this.setState({
            fileDetails: {
                ...fileDetails,
                suitIsFiled,
                suitDate,
                court,
                docketNumber,
                courtNotes
            },
            courtInfoDialog: false
        })
    }

    onSaveFile = async () => {
        let { fileDetails, relationshipsToAdd, relationshipsToRemove } = this.state;
        if (!fileDetails)
            return;
        this.setState({ submitInProgress: true })
        let updateFileResponse = await FileService.SaveFileDetails(fileDetails)
        if (Server.response(updateFileResponse)) {
            this.setState({ fileDetails: updateFileResponse.data, fileNumberCache: updateFileResponse.data.fileNumber });

        } else {
            this.popMessage(updateFileResponse.message, false);
            this.setState({ submitInProgress: false })
            return
        }
        if (relationshipsToAdd.length > 0) {
            for (let i = 0; i < relationshipsToAdd.length; i++) {
                const relation = relationshipsToAdd[i];
                let relationAddResponse = await FileService.AddContact(relation);
                if (Server.response(relationAddResponse)) {
                    relationshipsToAdd = relationshipsToAdd.splice(i, 1)
                    this.setState({ relationshipsToAdd })
                } else {
                    this.popMessage(relationAddResponse.message, false);
                    this.setState({ submitInProgress: false })
                    return;
                }
            }
        }
        if (relationshipsToRemove.length > 0) {
            for (let i = 0; i < relationshipsToRemove.length; i++) {
                const relation = relationshipsToRemove[i];
                await FileService.RemoveContact(relation);
            }
            this.setState({ relationshipsToRemove: []})
        }
        this.setState({ submitInProgress: false })
        this.popMessage(updateFileResponse.message, true);
    }

    onContactSelected = (contactId: number) => {
        this.props.history.push(`/contact/${contactId}`)
    }

    onAddNewContactToFile = (fileId: number) => {
        this.props.history.push(`/add-contact/${fileId}`)
    }

    onAddExistingContactToFile = () => {
        this.setState({ contactSearchDialog: true })
    }

    onNewRelationSelected = (contact: Contact, relation: string) => {
        const { fileDetails, relationshipsToAdd, contactFileRelationships } = this.state;
        if (!fileDetails)
            return;
        if (contactFileRelationships.find(r => r.contactId === contact.id)) {
            this.popMessage(`${contact.name} is already on the file`, false);
            return;
        }


        let relationship: ContactFileRelationship = {
            contactId: contact.id,
            contactName: contact.name,
            fileNumber: fileDetails.fileNumber,
            detailsId: fileDetails.id,
            type: fileDetails.type,
            isClosed: fileDetails.isClosed,
            relation,
            id: 0
        }

        this.setState({
            relationshipsToAdd: [...relationshipsToAdd, relationship],
            contactFileRelationships: [...contactFileRelationships, relationship]
        });
    }

    onRelationshipRemoved = (relationship: ContactFileRelationship) => {
        const { relationshipsToRemove, relationshipsToAdd, contactFileRelationships } = this.state;

        this.setState({
            relationshipsToAdd: relationshipsToAdd.filter(r => r.contactId != relationship.contactId),
            contactFileRelationships: contactFileRelationships.filter(r => r.contactId != relationship.contactId),
            relationshipsToRemove: [...relationshipsToRemove, relationship]
        });
    }

    popMessage = (message: string, success: boolean) => {
        this.setState({
            success: success ? message : undefined,
            error: !success ? message : undefined
        });

        if (success)
            setTimeout(() => { this.setState({ success: undefined, }) }, 1500)
    }

    render() {
        const { classes } = this.props
        const { fileNumberCache, fileDetails, contactFileRelationships, submitInProgress, success, error } = this.state

        if (!fileDetails)
            return <PageLoader />

        return (
            <Grid container direction="row" justify="center" alignItems="stretch" style={{ paddingTop: 15 }}>
                <Grid item>
                    <Paper className={classes.paper}>
                        <Typography variant="h4" align="center">File: {fileNumberCache}</Typography>
                        <Grid container direction="row" justify="center">
                            <Grid item className={classes.section}>
                                <Typography className={classes.sectionHeader} variant="h5" align="center">Contacts</Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Relation</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            contactFileRelationships.length === 0
                                                ?
                                                <TableRow>
                                                    <TableCell>No Contacts Found</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                                :
                                                contactFileRelationships.map(relation => (
                                                    <TableRow key={relation.id}>
                                                        <TableCell>
                                                            <a
                                                                href={`/contact/${relation.contactId}`}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.onContactSelected(relation.contactId)
                                                                    return false;
                                                                }}
                                                            >
                                                                {relation.contactName}
                                                            </a>
                                                        </TableCell>
                                                        <TableCell>{relation.relation}</TableCell>
                                                        <TableCell>
                                                            <a
                                                                href="remove"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.onRelationshipRemoved(relation);
                                                                    return false;
                                                                }}
                                                            >
                                                                Remove
                                                            </a>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                        }
                                    </TableBody>
                                </Table>
                                <Grid container justify="center" spacing={8} style={{ marginTop: 10 }}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disabled={submitInProgress}
                                            onClick={() => this.onAddNewContactToFile(fileDetails.id)}>
                                            New Contact
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disabled={submitInProgress}
                                            onClick={() => this.onAddExistingContactToFile()}>
                                            Existing Contact
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.section}>
                                <FileInfoSection
                                    fileDetails={fileDetails}
                                    classes={classes}
                                    disabled={submitInProgress}
                                    onChangeFileNumber={fileNumber => this.setState({ fileDetails: { ...fileDetails, fileNumber } })}
                                    onChangeFileName={name => this.setState({ fileDetails: { ...fileDetails, name } })}
                                    onChangeFileType={type => this.setState({ fileDetails: { ...fileDetails, type } })}
                                    onChangeNotes={notes => this.setState({ fileDetails: { ...fileDetails, notes } })}
                                />
                            </Grid>
                            <Grid item className={classes.section}>
                                <CourtSection
                                    fileDetails={fileDetails}
                                    classes={classes}
                                    disabled={submitInProgress}
                                    onOpenCourtDialog={() => this.setState({ courtInfoDialog: true })}
                                />
                            </Grid>
                            <Grid item className={classes.section}>
                                <StatusSection
                                    fileDetails={fileDetails}
                                    classes={classes}
                                    disabled={submitInProgress}
                                    onOpenCloseFileDialog={() => this.setState({ closeFileDialog: true })}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify="center" >
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.saveButton}
                                    disabled={submitInProgress}
                                    onClick={this.onSaveFile}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <CloseFileDialog
                    fileDetails={fileDetails}
                    open={this.state.closeFileDialog}
                    onClose={() => this.setState({ closeFileDialog: false })}
                    onSave={this.onUpdateCloseFile}
                />
                <CourtInfoDialog
                    fileDetails={fileDetails}
                    courts={this.state.courts}
                    open={this.state.courtInfoDialog}
                    onClose={() => this.setState({ courtInfoDialog: false })}
                    onSave={this.onUpdateCourtInfo}
                />
                <SearchContactDialog
                    open={this.state.contactSearchDialog}
                    onClose={() => this.setState({ contactSearchDialog: false })}
                    onNewRelationSelected={this.onNewRelationSelected}
                />
                <SuccessSnackbar successMessage={success} onClose={() => this.setState({ success: undefined })} />
                <ErrorSnackbar errorMessage={error} onClose={() => this.setState({ error: undefined })} />
            </Grid>
        )
    }
}

export default withRoot(withStyles(styles)(FileDetail));

interface FileInfoSectionProps extends WithStyles<typeof styles> {
    fileDetails: FileDetails
    disabled: boolean
    onChangeFileNumber: (fileNumber: string) => void
    onChangeFileName: (fileName: string) => void;
    onChangeFileType: (fileType: string) => void
    onChangeNotes: (notes: string) => void
}

const FileInfoSection: React.SFC<FileInfoSectionProps> = props => {
    const { fileDetails, classes, disabled } = props;
    return (
        <>
            <Typography className={classes.sectionHeader} variant="h5" align="center">File Info</Typography>
            <FormControl required>
                <TextField
                    label="File Number"
                    className={classes.formField}
                    value={fileDetails.fileNumber}
                    onChange={e => props.onChangeFileNumber(e.target.value)}
                    disabled={disabled}
                    required
                />
            </FormControl>
            <br />
            <FormControl required>
                <TextField
                    label="File Name"
                    className={classes.formField}
                    value={fileDetails.name}
                    onChange={e => props.onChangeFileName(e.target.value)}
                    disabled={disabled}
                    required
                />
            </FormControl>
            <br />
            <FormControl required>
                <InputLabel htmlFor="file-type">File Type</InputLabel>
                <Select
                    value={fileDetails.type}
                    className={classes.formField}
                    onChange={e => props.onChangeFileType(e.target.value)}
                    inputProps={{
                        id: 'file-type',
                    }}
                    disabled={disabled}
                >
                    <MenuItem value="">
                        <em>Select Type</em>
                    </MenuItem>
                    <MenuItem value='Succession'>Succession</MenuItem>
                    <MenuItem value='Will Package'>Will Package</MenuItem>
                    <MenuItem value='Other'>Other</MenuItem>
                    <MenuItem value='Insurance Defense'>Insurance Defense</MenuItem>
                    <MenuItem value='LLC Organizations'>LLC Organizations</MenuItem>
                    <MenuItem value='Commercial'>Commercial</MenuItem>
                    <MenuItem value='Collection'>Collection</MenuItem>
                    <MenuItem value='Personal Injury'>Personal Injury</MenuItem>
                    <MenuItem value='General Advices'>General Advices</MenuItem>
                    <MenuItem value='Foreclosure'>Foreclosure</MenuItem>
                </Select>
            </FormControl>
            <br />
            <TextField
                label="Notes"
                className={classes.formField}
                value={fileDetails.notes ? fileDetails.notes : ""}
                onChange={e => props.onChangeNotes(e.target.value)}
                multiline
                disabled={disabled}
            />
        </>
    )
}

interface CourtSectionProps extends WithStyles<typeof styles> {
    fileDetails: FileDetails
    disabled: boolean
    onOpenCourtDialog: () => void
}

const CourtSection: React.SFC<CourtSectionProps> = props => {
    const { classes, fileDetails, disabled } = props;
    return (
        <>
            <Typography className={classes.sectionHeader} variant="h5" align="center">Court Info</Typography>
            {
                !fileDetails.suitDate && !fileDetails.court && !fileDetails.docketNumber && !fileDetails.courtNotes
                &&
                <Typography align="center" variant="body1">Not Set</Typography>
            }
            {
                fileDetails.suitDate
                &&
                <Typography variant="body1">Suit Date: <strong>{fileDetails.suitDate.toLocaleDateString()}</strong></Typography>
            }
            {
                fileDetails.court
                &&
                <Typography variant="body1">Court Location: <strong>{fileDetails.court}</strong></Typography>
            }
            {
                fileDetails.docketNumber
                &&
                <Typography variant="body1">Docket Number: <strong>{fileDetails.docketNumber}</strong></Typography>
            }
            {
                fileDetails.courtNotes
                &&
                <Typography variant="body1">Court Notes:</Typography>
            }
            {
                fileDetails.courtNotes
                &&
                fileDetails.courtNotes
                    .split("\n")
                    .map(notes =>
                        <Typography variant="body1" style={{ paddingLeft: 10, maxWidth: 250 }} >{notes}</Typography>
                    )
            }
            <Grid container justify="center" style={{ marginTop: 10 }}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={disabled}
                        onClick={props.onOpenCourtDialog}>
                        Change
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

interface StatusSectionProps extends WithStyles<typeof styles> {
    fileDetails: FileDetails
    disabled: boolean
    onOpenCloseFileDialog: () => void
}

const StatusSection: React.SFC<StatusSectionProps> = props => {
    const { classes, fileDetails, disabled } = props;
    return (
        <>
            <Typography className={classes.sectionHeader} variant="h5" align="center">Status</Typography>
            <Typography variant="body1">File Status: <strong>{fileDetails.isClosed ? "Closed" : "Open"}</strong></Typography>
            <Typography variant="body1">Opened: <strong>{fileDetails.opened.toLocaleDateString()}</strong></Typography>
            {
                fileDetails.isClosed
                &&
                <>
                    <Typography variant="body1">Closed: <strong>{fileDetails.closed ? fileDetails.closed.toLocaleDateString() : ""}</strong></Typography>
                    <Typography variant="body1">Retain Until: <strong>{fileDetails.retainUntil ? fileDetails.retainUntil.toLocaleDateString() : ""}</strong></Typography>
                </>
            }
            {
                fileDetails.disposedDate
                &&
                <>
                    <Typography variant="body1">Disposed: <strong>{fileDetails.disposedDate.toLocaleDateString()}</strong></Typography>
                    {
                        fileDetails.disposalStatus
                        &&
                        <Typography variant="body1">Disposal Status:</Typography>
                    }
                    {
                        fileDetails.disposalStatus
                        &&
                        fileDetails.disposalStatus
                            .split("\n")
                            .map(status =>
                                <Typography variant="body1" style={{ paddingLeft: 10, maxWidth: 250 }} >{status}</Typography>
                            )
                    }
                    <Typography variant="body1">Shredded: <strong>{fileDetails.shredded ? "Yes" : "No"}</strong></Typography>
                    {
                        (
                            fileDetails.fileDocsRetained ||
                            fileDetails.judgementDocsRetained ||
                            fileDetails.receiptReleaseDocsRetained ||
                            fileDetails.otherDocsRetained
                        )
                        &&
                        <Typography variant="body1">Retained Documents:</Typography>
                    }
                    {
                        fileDetails.fileDocsRetained
                        &&
                        <Typography className={classes.sortLines} variant="overline">File Documents</Typography>
                    }
                    {
                        fileDetails.judgementDocsRetained
                        &&
                        <Typography className={classes.sortLines} variant="overline">Judgement Documents</Typography>
                    }
                    {
                        fileDetails.receiptReleaseDocsRetained
                        &&
                        <Typography className={classes.sortLines} variant="overline">Receipt Release Documents</Typography>
                    }
                    {
                        fileDetails.otherDocsRetained && fileDetails.otherRetainedDocDescription
                        &&
                        fileDetails.otherRetainedDocDescription.split("\n").map(docDescription => (
                            <Typography className={classes.sortLines} variant="overline">{docDescription}</Typography>
                        ))
                    }
                </>
            }
            <Grid container justify="center" style={{ marginTop: 10 }}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={disabled}
                        onClick={props.onOpenCloseFileDialog}>
                        Change
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}