import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { createStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';
import PageLoader from '../components/PageLoader';
import ErrorSnackbar from '../components/ErrorSnackbar';
import { ChangeHistory, HistoryService } from '../services/HistoryService';
import { Server } from '../utilities/WebClient';
import { FileService } from '../services/FileService';
import { ContactService } from '../services/ContactService';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 900,
            padding: 20,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{}> { }
interface State {
    loading: boolean
    loadingMore: boolean
    hasMore: boolean
    history: ChangeHistory[]
    error?: string
}

class History extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            loadingMore: false,
            hasMore: true,
            history: [],
        }
    }

    async componentDidMount() {
        let take = 30;
        let response = await HistoryService.Search(take);
        if (Server.response(response)) {
            this.setState({ loading: false, history: response.data, hasMore: response.data.length === take })
        } else {
            this.setState({ loading: false, error: response.message })
        }
    }

    async loadMore() {
        let history = this.state.history;
        if (history.length === 0)
            return;

        let lastHistory = history[history.length - 1];
        let take = 30;
        let response = await HistoryService.Search(take, lastHistory.timeOfAction);
        if (Server.response(response)) {
            response.data.forEach(h => {
                history.push(h);
            });
            this.setState({ loading: false, history, hasMore: take === response.data.length })
        } else {
            this.setState({ loading: false, error: response.message })
        }
    }

    getMessage = (history: ChangeHistory) => {
        const snapshot = history.snapshot;

        let message = history.username;
        switch (history.action) {
            case "Add":
                message += " added";
                break;
            case "Update":
                message += " updated";
                break;
            case "Remove":
                message += " removed";
                break;
        }

        if (FileService.isFile(snapshot)) {
            message += ` file ${snapshot.fileNumber}`
        } else if (ContactService.isContact(snapshot)) {
            message += ` ${snapshot.name}`
        } else {
            if (history.action === "Add")
                message += ` ${snapshot.contactName} to file ${snapshot.fileNumber} as ${snapshot.relation === "Client" ? "a" : "an"} ${snapshot.relation}`;
            else
                message += ` ${snapshot.contactName}'s relationship to file ${snapshot.fileNumber}`;
        }

        return message;
    }

    render() {
        const { loading, history: historyItems } = this.state;
        const { classes } = this.props;

        if (loading)
            return <PageLoader />

        return (
            <>
                <div style={{ height: "100%", overflow: "auto", paddingTop: 15 }}>
                    <InfiniteScroll
                        pageStart={0}
                        threshold={0}
                        loadMore={async () => await this.loadMore()}
                        hasMore={this.state.hasMore}
                        useWindow={false}
                        loader={<div key="loader" style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                                </Typography>
                        </div>}
                    >
                        {
                            historyItems.map(history =>
                                <Card className={classes.paper}>
                                    <CardHeader
                                        subheader={`${history.timeOfAction.toLocaleDateString()} - ${history.type}`}
                                        title={this.getMessage(history)}
                                    />
                                    {/* <Typography variant="h6" align="center"></Typography> */}
                                </Card>
                            )
                        }
                    </InfiniteScroll>

                </div>
                <ErrorSnackbar
                    errorMessage={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
            </>
        );
    }
}

export default withRoot(withStyles(styles)(History));