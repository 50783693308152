export class DateUtil {
    static toInputFormat(date: Date) {
        const dateParts = date.toLocaleDateString().split('/');
        let month = dateParts[0].length === 1 ? `0${dateParts[0]}`: dateParts[0]
        return `${dateParts[2]}-${month}-${dateParts[1]}`
    }

    static inputFormatToDate(dateStr: string) {
        const dateParts = dateStr.split('-');
        const year = +dateParts[0]
        const month = (+dateParts[1]) - 1
        const day = +dateParts[2]
        return new Date(year, month, day)
    }
}